.navigation__transition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.navigation__transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.navigation__transition-exit {
  opacity: 1;
  transform: translateX(0);
}
.navigation__transition-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition:all 300ms ease;
}